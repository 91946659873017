import { all, takeLatest } from 'redux-saga/effects'

import {
  loadNabolagSaga,
  loadNabolagPoisSaga,
  loadNabolagPoisBoundsSaga,
  loadNabolagSkiTracksBoundsSaga,
} from './nabolag/saga'
import { getAddressSuggestionsSaga, getAddressComparisonSaga } from './address/saga'
import { toggleDialogSaga } from './ui/saga'
import { actionTypes as nabolagActions } from './nabolag/actions'
import { actionTypes as addressActions } from './address/actions'
import { actionTypes as uiActions } from './ui/actions'

function* rootSaga() {
  yield all([
    takeLatest(nabolagActions.LOAD_NABOLAG, loadNabolagSaga),
    takeLatest(nabolagActions.LOAD_POIS, loadNabolagPoisSaga),
    takeLatest(nabolagActions.LOAD_POIS_BOUNDS, loadNabolagPoisBoundsSaga),
    takeLatest(addressActions.GET_ADDRESSES, getAddressSuggestionsSaga),
    takeLatest(addressActions.GET_ADDRESS_COMPARISON, getAddressComparisonSaga),
    takeLatest(uiActions.TOGGLE_DIALOG, toggleDialogSaga),
    takeLatest(nabolagActions.LOAD_SKI_TRACKS, loadNabolagSkiTracksBoundsSaga),
  ])
}

export default rootSaga
