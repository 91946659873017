import theme from '../../styles/base'

import { actionTypes } from './actions'

export const initialState = {
  dialogOpen: false,
  isMobile: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_DIALOG:
      return {
        dialogOpen: !state.dialogOpen,
      }
    case actionTypes.WINDOW_SIZE:
      return {
        ...state,
        isMobile: action.payload <= theme.media.sizes.small,
      }
    default:
      return state
  }
}

export default reducer
