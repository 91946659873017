import { actionTypes } from './actions'

export const initialState = {
  error: false,
  data: {},
  loaded: false,
  pois: [],
  skiTracks: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_NABOLAG_FAILURE:
      return {
        data: {},
        error: {
          ...action.error.error,
          nabolagId: action.error.payload.id,
        },
      }
    case actionTypes.LOAD_NABOLAG_SUCCESS:
      return {
        ...state,
        data: action.data,
        loaded: true,
      }
    case actionTypes.LOAD_POIS_SUCCESS:
      return {
        ...state,
        pois: action.data,
      }
    case actionTypes.LOAD_SKI_TRACKS_SUCCESS:
      return {
        ...state,
        skiTracks: action.data,
      }
    default:
      return state
  }
}

export default reducer
