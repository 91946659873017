import { combineReducers } from 'redux'

import nabolag from './nabolag/reducer'
import address from './address/reducer'
import ui from './ui/reducer'
import travelTime from './travel-time/reducer'

const rootReducer = combineReducers({
  nabolag,
  address,
  ui,
  travelTime,
})

export default rootReducer
