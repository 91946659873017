import { put } from 'redux-saga/effects'

import {
  getAddressSuggestionsSuccess,
  setSearchWord,
  resetAddressSuggestions,
  loadAddressComparisonActionSuccess,
  loadAddressComparisonActionFailed,
} from './actions'
import { getAddressSuggestions, getAddressComparison } from '../api'

function* getAddressSuggestionsSaga(action) {
  try {
    if (action.searchStr) {
      yield put(setSearchWord(action.searchStr))
      const res = yield getAddressSuggestions(action.searchStr)
      let data = []
      if (res.status === 200) {
        data = yield res.json()
      }
      yield put(getAddressSuggestionsSuccess(data))
    } else {
      // If searchStr is empty
      yield put(resetAddressSuggestions())
    }
  } catch (err) {
    console.log('Error', err)
  }
}

function* getAddressComparisonSaga(action) {
  try {
    if (action.query.orderLineId) {
      const res = yield getAddressComparison(action.query)
      const data = yield res.json()
      yield put(loadAddressComparisonActionSuccess(data))
    }
  } catch (err) {
    console.log('Error: ', err)
    yield put(loadAddressComparisonActionFailed(err))
  }
}

export { getAddressSuggestionsSaga, getAddressComparisonSaga }
