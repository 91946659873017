import { actionTypes } from './actions'

export const initialState = {
  error: false,
  searchWord: '',
  suggestions: [],
  selectedComparisson: null,
  comparisonData: null,
  comparisonDataLoading: false,
  preferedCity: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        suggestions: action.data,
      }
    case actionTypes.RESET_ADDRESSES:
      return {
        ...state,
        suggestions: [],
      }
    case actionTypes.SET_SEARCHWORD:
      return {
        ...state,
        searchWord: action.searchWord,
      }
    case actionTypes.GET_ADDRESS_COMPARISON: {
      return {
        ...state,
        comparisonDataLoading: true,
      }
    }
    case actionTypes.GET_ADDRESS_COMPARISON_SUCCESS:
      return {
        ...state,
        comparisonDataLoading: false,
        comparisonData: action.data,
      }
    case actionTypes.SET_PREFERED_CITY:
      return {
        ...state,
        preferedCity: action.city,
      }
    case actionTypes.GET_COMPARISON_FAILED:
      return {
        ...state,
        comparisonDataLoading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default reducer
