import { put } from 'redux-saga/effects'
import es6promise from 'es6-promise'
import 'isomorphic-unfetch'

import {
  failure,
  loadNabolagSuccess,
  loadNabolagPoisSuccess,
  loadNabolagSkiTracksSuccess,
} from './actions'

import { getProfile, getPoisByLatLng, getPoisByBound, getSkiTracksByBound } from '../api'

es6promise.polyfill()

export function* loadNabolagSaga(action) {
  try {
    const res = yield getProfile(action.id, action.theme)
    const data = yield res.json()
    yield put(loadNabolagSuccess(data))
  } catch (err) {
    yield put(
      failure({
        error: err,
        payload: action,
      }),
    )
  }
}

export function* loadNabolagPoisSaga(action) {
  try {
    const res = yield getPoisByLatLng(action.location)
    const data = yield res.json()
    yield put(loadNabolagPoisSuccess(data))
  } catch (err) {
    yield put(failure(err))
  }
}

export function* loadNabolagPoisBoundsSaga(action) {
  try {
    const res = yield getPoisByBound(action.bounds)
    const data = yield res.json()
    yield put(loadNabolagPoisSuccess(data))
  } catch (err) {
    yield put(failure(err))
  }
}

export function* loadNabolagSkiTracksBoundsSaga(action) {
  try {
    const res = yield getSkiTracksByBound(action.bounds)
    const data = yield res.json()
    yield put(loadNabolagSkiTracksSuccess(data))
  } catch (err) {
    yield put(failure(err))
  }
}

export default { loadNabolagSaga, loadNabolagPoisSaga }
