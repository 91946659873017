import { actionTypes } from './actions'

export const initialState = {
  error: null,
  hasCheckedStorage: false,
  addresses: [],
  routeInfo: {},
}

const updateAddressByIndex = (addresses, index, data = {}) => [
  ...addresses.slice(0, index),
  {
    ...addresses[index],
    ...data,
  },
  ...addresses.slice(index + 1),
]

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPEND_ADDRESS:
      return {
        ...state,
        addresses: [
          ...state.addresses,
          {
            ...action.payload.address,
            isFetchingRoute: false,
            hasFetchedRoute: false,
            routeFetchError: null,
          },
        ],
      }
    case actionTypes.APPEND_ADDRESS_ERROR:
    case actionTypes.ADDRESS_EXISTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }
    case actionTypes.GET_STORED_ADDRESSES:
      return {
        ...state,
        addresses: action.payload.addresses.map((adr) => ({
          ...adr,
          isFetchingPublicRoute: false,
          hasFetchedPublicRoute: false,
          isFetchingRoute: false,
          hasFetchedRoute: false,
          routeFetchError: null,
        })),
      }
    case actionTypes.CLEAR_ADDRESS_ERROR:
      return {
        ...state,
        error: null,
      }
    case actionTypes.INIT_ROUTE_FETCH: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, { isFetchingRoute: true }),
      }
    }
    case actionTypes.COMPLETE_ROUTE_FETCH: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          isFetchingRoute: false,
          hasFetchedRoute: true,
        }),

        routeInfo: {
          ...state.routeInfo,
          [action.payload.id]: [
            ...(state.routeInfo[action.payload.id] || []),
            ...action.payload.routeInfo,
          ],
        },
      }
    }
    case actionTypes.ROUTE_FETCH_FAILED: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          isFetchingRoute: false,
          hasFetchedRoute: true,
          routeFetchError: action.payload.error,
        }),
      }
    }
    case actionTypes.INIT_PUBLIC_ROUTE_FETCH: {
      const addressIndex = state.addresses.findIndex((addr) => addr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }
      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          isFetchingPublicRoute: true,
          hasFetchedPublicRoute: false,
          routeFetchPublicError: null,
        }),
      }
    }
    case actionTypes.COMPLETE_PUBLIC_ROUTE_FETCH: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          isFetchingPublicRoute: false,
          hasFetchedPublicRoute: true,
          routeFetchPublicError: null,
        }),

        routeInfo: {
          ...state.routeInfo,
          [action.payload.id]: [
            ...(Array.isArray(action.payload.routeInfo)
              ? action.payload.routeInfo.map((routeInfo) => ({
                  mode: 'public',
                  ...routeInfo,
                }))
              : []),
            ...(state.routeInfo[action.payload.id] || []),
          ],
        },
      }
    }
    case actionTypes.PUBLIC_ROUTE_FETCH_FAILED: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          isFetchingPublicRoute: false,
          hasFetchedPublicRoute: true,
          routeFetchPublicError: action.payload.error,
        }),
      }
    }
    case actionTypes.UPDATE_ADDRESS_ALIAS: {
      const addressIndex = state.addresses.findIndex((adr) => adr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: updateAddressByIndex(state.addresses, addressIndex, {
          alias: action.payload.alias,
        }),
      }
    }
    case actionTypes.DELETE_ADDRESS: {
      const addressIndex = state.addresses.findIndex((addr) => addr.id === action.payload.id)
      if (addressIndex === -1) {
        return state
      }

      return {
        ...state,
        addresses: [
          ...state.addresses.slice(0, addressIndex),
          ...state.addresses.slice(addressIndex + 1),
        ],
      }
    }
    case actionTypes.DELETE_ROUTE_INFO: {
      const routeInfo = JSON.parse(JSON.stringify(state.routeInfo)) // duplicate object
      delete routeInfo[action.payload.addressId]

      return {
        ...state,
        routeInfo,
      }
    }
    case actionTypes.SET_SELECTED_ROUTE_TYPE: {
      const { addressId, travelOptionType } = action.payload

      const newRouteInfo = state.routeInfo[addressId].map((route) => {
        if (Object.getOwnPropertyDescriptor(route, 'type')) {
          return {
            ...route,
            selected: route.type === travelOptionType,
          }
        }
        return route
      })

      return {
        ...state,
        routeInfo: {
          ...state.routeInfo,
          [addressId]: newRouteInfo,
        },
      }
    }
    case actionTypes.SET_HAS_CHECKED_STORAGE:
      return {
        ...state,
        hasCheckedStorage: action.payload,
      }
    default:
      return state
  }
}

export default reducer
