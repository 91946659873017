import { createStore, applyMiddleware } from 'redux'
import withRedux from 'next-redux-wrapper'
import nextReduxSaga from 'next-redux-saga'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducers'
import rootSaga from './saga'

const bindMiddleware = (middleware) => applyMiddleware(...middleware)

export const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(bindMiddleware([sagaMiddleware, thunkMiddleware])),
  )
  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}

export const withReduxSaga = (BaseComponent) =>
  withRedux(configureStore)(nextReduxSaga(BaseComponent))
