export const actionTypes = {
  GET_ADDRESSES: 'GET_ADDRESSES',
  GET_ADDRESSES_SUCCESS: 'GET_ADDRESS_SUCCESS',
  RESET_ADDRESSES: 'RESET_ADDRESSES',
  SET_SEARCHWORD: 'SET_SEARCHWORD',
  GET_ADDRESS_COMPARISON: 'GET_ADDRESS_COMPARISON',
  GET_ADDRESS_COMPARISON_SUCCESS: 'GET_ADDRESS_COMPARISON_SUCCESS',
  SET_PREFERED_CITY: 'SET_PREFERED_CITY',
  GET_COMPARISON_FAILED: 'GET_COMPARISON_FAILED',
}

export const getAddressSuggestions = searchStr => ({
  type: actionTypes.GET_ADDRESSES,
  searchStr,
})

export function getAddressSuggestionsSuccess(data) {
  return {
    type: actionTypes.GET_ADDRESSES_SUCCESS,
    data,
  }
}

export function resetAddressSuggestions() {
  return {
    type: actionTypes.RESET_ADDRESSES,
  }
}

export function setSearchWord(searchWord) {
  return {
    type: actionTypes.SET_SEARCHWORD,
    searchWord,
  }
}

export function setPreferedCity(city) {
  return {
    type: actionTypes.SET_PREFERED_CITY,
    city,
  }
}

export function loadAddressComparisonActionSuccess(data) {
  return {
    type: actionTypes.GET_ADDRESS_COMPARISON_SUCCESS,
    data,
  }
}

export function loadAddressComparisonAction(query) {
  return {
    type: actionTypes.GET_ADDRESS_COMPARISON,
    query,
  }
}

export function loadAddressComparisonActionFailed(error) {
  console.log(error)
  return {
    type: actionTypes.GET_COMPARISON_FAILED,
    error,
  }
}

export default {
  getAddressSuggestions,
  resetAddressSuggestions,
  loadAddressComparisonActionFailed,
  actionTypes,
}
