import React from 'react'
import PropTypes from 'prop-types'
import App from 'next/app'
import { compose } from 'recompose'
import { Provider } from 'react-redux'
import Router, { withRouter } from 'next/router'
import storage from 'local-storage-fallback'
import debounce from 'debounce'
import Head from 'next/head'

import '../polyfills'

import { withReduxSaga } from '../store'
import { appWithTranslation } from '../../i18n'
import { initAnalytics, trackPageView } from '../utils/analytics'
import { getWindowSizeAction } from '../store/ui/actions'

Router.events.on('routeChangeComplete', (url) => {
  trackPageView(url)
})

const resizeHandler = (dispatch) => {
  dispatch(getWindowSizeAction(window.innerWidth))
}

class DP2App extends App {
  state = {
    hasFetchedUser: false,
  }

  componentDidMount() {
    const { store, router } = this.props

    store.dispatch(getWindowSizeAction(window.innerWidth))

    this.setState({
      hasFetchedUser: true,
    })
    initAnalytics()

    window.onresize = debounce(() => resizeHandler(store.dispatch), 200)

    trackPageView(router.asPath)
    storage.setItem('previousPage', router.asPath)
  }

  componentDidUpdate() {
    const { router } = this.props
    storage.setItem('previousPage', router.asPath)
  }

  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
  }

  componentWillUnmount() {
    if (
      typeof window.onresize.clear === 'function' &&
      typeof window.onresize.flush === 'function'
    ) {
      window.onresize.clear()
      window.onresize.flush()
    }
  }

  render() {
    const { Component, pageProps, store } = this.props

    if (this.state.error) {
      return <div>Error happened</div>
    }

    // block render until we've checked for a signed in user
    if (!this.state.hasFetchedUser) return null

    return (
      <>
        <Head>
          <meta key="viewport" name="viewport" content="initial-scale=1.0, width=device-width" />
        </Head>
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </>
    )
  }
}

DP2App.propTypes = {
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(withRouter, withReduxSaga, appWithTranslation)(DP2App)
