export const actionTypes = {
  LOAD_NABOLAG_FAILURE: 'FAILURE',
  LOAD_NABOLAG: 'LOAD_NABOLAG',
  LOAD_NABOLAG_SUCCESS: 'LOAD_NABOLAG_SUCCESS',
  LOAD_POIS: 'LOAD_POIS',
  LOAD_POIS_SUCCESS: 'LOAD_POIS_SUCCESS',
  LOAD_POIS_BOUNDS: 'LOAD_POIS_BOUNDS',
  LOAD_SKI_TRACKS: 'LOAD_SKI_TRACKS',
  LOAD_SKI_TRACKS_SUCCESS: 'LOAD_SKI_TRACKS_SUCCESS',
}

export function failure(error) {
  return {
    type: actionTypes.LOAD_NABOLAG_FAILURE,
    error,
  }
}

export function loadNabolagAction(id, theme) {
  return {
    type: actionTypes.LOAD_NABOLAG,
    id,
    theme,
  }
}

export function loadNabolagPoisAction(location) {
  return {
    type: actionTypes.LOAD_POIS,
    location,
  }
}

export function loadNabolagPoisWithBoundsAction(bounds) {
  return {
    type: actionTypes.LOAD_POIS_BOUNDS,
    bounds,
  }
}

export function loadSkiTracksWithBoundsAction(bounds) {
  return {
    type: actionTypes.LOAD_SKI_TRACKS,
    bounds,
  }
}

export function loadNabolagSuccess(data) {
  return {
    type: actionTypes.LOAD_NABOLAG_SUCCESS,
    data,
  }
}

export function loadNabolagPoisSuccess(data) {
  return {
    type: actionTypes.LOAD_POIS_SUCCESS,
    data,
  }
}

export function loadNabolagSkiTracksSuccess(data) {
  return {
    type: actionTypes.LOAD_SKI_TRACKS_SUCCESS,
    data,
  }
}

export default {
  loadNabolagAction,
  loadNabolagSuccess,
  loadNabolagPoisSuccess,
  loadSkiTracksWithBoundsAction,
  loadNabolagSkiTracksSuccess,
  failure,
  actionTypes,
}
